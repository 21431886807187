@import '../../../node_modules/@angular/cdk/overlay-prebuilt.css';
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}
.material-icons {
  font-size: 18px;
  margin-top: 1px;
}
.feather {
  width: 16px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  top: 50% !important;
  transform: translateY(-50%) !important;
}
.custom-tag {
  background-color: #333333;
}
.clickable-tag {
  cursor: pointer !important;
}
.ant-alert-close-icon .anticon-close {
  color: black !important;
}
.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child) {
  padding: 0 !important;
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  margin-top: 0 !important;
}
.ant-input-group-addon .ant-select-open .ant-select-selection,
.ant-input-group-addon .ant-select-focused .ant-select-selection {
  color: inherit !important;
}
.patreon-widget {
  margin-top: 25px;
}
.patreon-widget > img {
  width: 175px;
}
.ant-input,
.ant-select,
.ant-collapse,
.ant-input-group-addon,
.ant-btn,
.ant-collapse-content,
.ant-select-selection,
.ant-input-number,
.ant-collapse-item,
.ant-list-item,
.ant-card-bordered {
  border-color: #444 !important;
}
.ant-alert-close-text {
  color: black !important;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0 !important;
}
.fake-form-label {
  visibility: hidden;
}
@font-face {
  font-family: FFXIVAppIcons;
  src: url("./assets/font/FFXIVAppIcons.ttf");
}
.companion-svg {
  font-family: 'FFXIVAppIcons';
}
@font-face {
  font-family: Teamcraft;
  src: url("./assets/font/Teamcraft.ttf");
}
.teamcraft-svg {
  font-family: 'Teamcraft';
}
.man-attacked-by-tetris-block {
  width: 18px;
  height: 18px;
  opacity: 0.65;
}
.man-attacked-by-tetris-block.anticon {
  margin-right: 10px;
}
.man-attacked-by-tetris-block.button-icon {
  margin-bottom: 6px;
}
.man-attacked-by-tetris-block:hover {
  opacity: 1;
}
*::-webkit-scrollbar,
*::-webkit-scrollbar,
*::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
*::-webkit-scrollbar-track,
*::-webkit-scrollbar-track,
*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
}
*::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb {
  background-color: #6e6e6e;
  outline: 1px solid #333;
}
.init-loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.init-loader-container .brought-to-you {
  padding-top: 20px;
  font-size: 20px;
  color: darkgrey;
  opacity: 0.7;
}
.ant-select-arrow {
  color: inherit;
}
nz-alert:not(.dark) .ant-alert-error {
  color: #ffa39e;
  background-color: #352525;
}
nz-alert:not(.dark) .ant-alert-info .ant-alert-message,
nz-alert:not(.dark) .ant-alert-warning .ant-alert-message {
  color: black !important;
}
nz-alert:not(.dark) .ant-alert-info .ant-alert-description,
nz-alert:not(.dark) .ant-alert-warning .ant-alert-description {
  color: black;
}
nz-alert.dark .ant-alert {
  background-color: #333333;
}
nz-alert.dark .ant-alert-info .ant-alert-message,
nz-alert.dark .ant-alert-warning .ant-alert-message,
nz-alert.dark .ant-alert-error .ant-alert-message,
nz-alert.dark .ant-alert-success .ant-alert-message {
  color: white !important;
}
nz-alert.dark .ant-alert-info .ant-alert-description,
nz-alert.dark .ant-alert-warning .ant-alert-description,
nz-alert.dark .ant-alert-error .ant-alert-description,
nz-alert.dark .ant-alert-success .ant-alert-description {
  color: white;
}
.ant-table-thead > tr.ant-table-row-hover > td,
.ant-table-tbody > tr.ant-table-row-hover > td,
.ant-table-thead > tr:hover > td,
.ant-table-tbody > tr:hover > td {
  background-color: rgba(128, 128, 128, 0.3) !important;
}
.ant-alert-success .ant-alert-message,
.ant-alert-warning .ant-alert-message,
.ant-alert-error .ant-alert-message,
.ant-alert-info .ant-alert-message {
  color: rgba(0, 0, 0, 0.8);
}
.input-number-with-addon {
  border-radius: 4px 0 0 4px;
}
.ant-btn-background-ghost:not(.ant-btn-primary) {
  color: rgba(255, 255, 255, 0.85) !important;
}
.ant-btn-circle {
  box-shadow: 0 0 0.5px 0 white;
}
.ant-btn-circle:not(.ant-btn-primary) {
  box-shadow: 0 0 0.5px 0 white inset;
}
.ant-menu-item .anticon:not(.db-icon) {
  font-size: 18px !important;
}
@media screen and (max-width: 575px) {
  .ant-modal-body {
    padding: 12px;
  }
}
.ant-layout-sider-zero-width-trigger {
  opacity: 0.8;
}
.ant-select-dropdown-menu-item:hover,
.ant-select-dropdown-menu-item-active,
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
}
.ant-menu-item-selected:not(.menu-link) {
  background-color: unset !important;
}
.ant-menu-item-active:not(.menu-link) {
  background-color: unset !important;
}
.page-container {
  padding: 20px 25px;
}
.page-container.overlay {
  padding: 0;
  height: calc(100% - 21px);
}
.spacer {
  flex: 1 1 auto;
}
.ngx-dnd-container.gu-empty {
  border: 2px dotted red;
}
.ngx-dnd-container:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.2);
}
.ngx-dnd-container .ex-moved {
  background-color: #e74c3c;
}
.ngx-dnd-container .ex-over {
  background-color: rgba(255, 255, 255, 0.3);
}
.ngx-dnd-container .handle {
  padding: 0 5px;
  margin-right: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: move;
}
.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE/Edge */
  user-select: none;
  /* non-prefixed version, currently
                         not supported by any browser */
}
.ngx-dnd-item {
  transition: opacity 0.4s ease-in-out;
  display: block;
}
.ngx-dnd-item:not(.has-handle):not(.move-disabled),
.ngx-dnd-item.has-handle [ngxdraghandle],
.ngx-dnd-item.has-handle [ngxDragHandle] {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.ngx-dnd-item .ngx-dnd-content {
  user-select: none;
}
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
.item-row.craftable {
  background-color: rgba(19, 168, 255, 0.3);
}
.item-row.craftable.odd {
  background-color: rgba(19, 168, 255, 0.4);
}
.item-row.done {
  background-color: rgba(94, 255, 91, 0.15);
  opacity: 0.6;
}
.item-row.done .ant-btn-primary {
  background-color: white !important;
}
.item-row.done.odd {
  background-color: rgba(94, 255, 91, 0.2);
}
.item-row.has-all-ingredients {
  border: 2px dashed rgba(19, 168, 255, 0.3);
}
.corners-right {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.corners-left {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
